.change-language-block {
	order: 2;
    display: inline-block;
}

header {
	position: sticky;
	top: 0;
	z-index: 999;
	background: var(--base-bordo);
	-webkit-box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
	-moz-box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
	box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
}

.header-block {
	height: 75px;
	padding: 0 !important;
}

.header-block .container {
	position: relative;
	height: 100%;
}

.header-block .navbar-brand {
	position: relative;
	left: 0;
	top: 0;
	z-index: 99;
	padding: 0;
	margin: 0;
	display: inline-block;
	height: 75px;
    width: 75px;
	/* height: 122px;
    width: 122px; */
	/* height: 147px;
    width: 147px; */
}

.header-nav-menu {
	position: absolute;
	display: flex;
	align-items: flex-end;
	top: 0;
	left: 0;
	border-bottom-right-radius: 14px;
}

.header-nav-menu:after {
	content: '';
    width: 100%;
    height: 15px;
    position: absolute;
    bottom: 0;
    background: #ffffff;
    border-bottom-right-radius: 14px;
    -webkit-box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
	-moz-box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
	box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
}

.switch-app-type {
	position: relative;
	bottom: 0;
	right: 0;
	z-index: 999;
}

.switch-app-type .form-check,
.change-language-block .form-check {
	margin: 0;
	padding: 0;
}

.switch-app-type .form-check .form-check-label {
	background: var(--base-graphit);
	padding: 0 27px;
	color: #ffffff !important;
    font-size: clamp(14px, 1.55vw, 18px);
    font-family: ubuntuMedium;
    height: 75px;
    display: flex;
    align-items: center;
	cursor: pointer;
}

.switch-app-type .form-check-input {
	display: none;
}

.switch-app-type .form-check:has(.form-check-input:checked) .form-check-label {
	color: var(--base-graphit) !important;
	background: #ffffff;
    
}

.switch-app-type .form-check:has(.form-check-input:checked) .form-check-label[for='personal_id'] {
	border-bottom-right-radius: 14px;
}

.switch-app-type .form-check:has(.form-check-input:checked) .form-check-label[for='business_id'] {
	border-top-left-radius: 14px;
}

.personal_id {
	background: var(--base-graphit);
}

.business_id {
	background: #ffffff;
	border-bottom-right-radius: 14px;
}

.business_id label {
	border-top-left-radius: 14px;
	border-bottom-right-radius: 14px;
}

.header-block .container-fluid {
	flex-wrap: nowrap !important;
}

.change-language-block .form-check-input {
	width: 40px;
	height: 40px;
	margin: 0;
	position: absolute;
	border: none;
	background: none;
	left: 23px;
	top: -6px;
	z-index: -1;
}

.form-check-input:checked[type=radio] {
	background: var(--base-bordo);
	background-image: none;
	border: 2px solid #ffffff !important;
	box-shadow: none;
}

.change-language-block form {
	height: 30px;
	border: 1px solid var(--base-bordo);
	border-radius: 20px;
	display: flex;
    justify-content: space-around;
    align-items: center;
	isolation: isolate;
	background: #ffffff;
}

.change-language-block .form-check-inline {
	position: relative;
	width: 38px;
	text-align: center;
}

/* .change-language-block .form-check-label {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;
} */

.change-language-block .form-check-input:focus {
	border: none;
	box-shadow: none;
}

.change-language-block .pagination {
	margin-bottom: 0;
	border: 1px solid var(--base-bordo);
	border-radius: 20px;
	/* overflow: hidden; */
	height: 30px;
	display: flex;
	align-items: center;
}

.change-language-block .pagination .page-item {
	width: 38px;
	text-align: center;
}

.change-language-block .pagination .page-item .page-link {
	border: none;
	/* border-radius: 20px; */
	padding: 0;
	color: var(--base-bordo);
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	z-index: 9;
	transition: all 0.4s;
	background: transparent;
	/* line-height: 1; */
	font-size: 18px;
	height: 100%;
}

.change-language-block .form-check .form-check-label {
	color: var(--base-bordo);
	font-weight: 600;
	text-transform: uppercase;
	z-index: 99;
	font-size: 18px;
	height: 100%;
	cursor: pointer;
}

.change-language-block .form-check.active .form-check-label {
	color: #ffffff !important;
}

.change-language-block .pagination .page-item.active .page-link {
	background: none;
	color: #ffffff;
	/* transition: all 0.2s; */
}

.change-language-block .pagination .page-item.active .page-link:before {
	content: '';
	width: 40px;
	height: 40px;
	position: absolute;
	top: -7px;
	left: -1px;
	background: var(--base-bordo);
	z-index: -1;
	border-radius: 50%;
}

.change-language-block .pagination .page-item:not(:first-child) .page-link {
	margin-left: 0 !important;
}

.user-type-icon {
	height: 35px;
	margin-right: 10px;
}

.user-type-icon .st0 {
	fill: var(--base-graphit);
}

.header-block .login-type-style {
	padding-right: 135px;
}

.header-block .login-type-style .nav-link {
	display: flex;
	align-items: center;
	font-size: clamp(14px, 1.55vw, 18px);
	text-transform: uppercase;
	color: var(--base-graphit);
	font-family: ubuntuMedium;
	transition: all 0.2s;
	position: relative;
}

.header-block .login-type-style .nav-link:first-child {
	margin-right: 25px;
}

.header-block .login-type-style .nav-link:hover {
	color: var(--base-bordo);
}

.header-block .login-type-style .nav-link:hover .user-type-icon .st0 {
	fill: var(--base-bordo);
}

.header-block .login-type-style .nav-link:hover .user-type-icon {
	transform: scale(1.1);
}

.header-menu-position {
	position: absolute;
	right: 30px;
}

.mobile-menu-label {
    font-family: ubuntuBold;
}

.custom-mobile-burger-menu {
	border: none !important;
	transform: scale(0.6);
}

.custom-mobile-burger-menu:focus {
	box-shadow: none !important;
}

.custom-mobile-burger-menu-icon {

}
