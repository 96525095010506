@media (max-width: 991.98px) {
	.advantages-item {
		padding-top: 45px;
		margin-bottom: 60px;
	}

	.advantages-item:last-child {
		margin-bottom: 0;
	}

	.header-block .login-type-style {
		padding-right: 25px;
	}

	/* .header-block .navbar-brand {
		height: 131px;
		width: 131px;
        min-width: 131px;
	} */

	.header-block .login-type-style .nav-link:first-child {
		margin-right: 20px;
	}
    
    /* .switch-app-type .form-check .form-check-label {
        height: 46px;
    } */

    .reviews-title {
        font-size: clamp(17px, 2.8vw, 24px) !important;
    }

    .reviews-content {
        font-size: clamp(16px, 2.5vw, 18px);
    }

    .reviews-item {
        margin-bottom: 20px;
    }

    .reviews-block {
        padding: 60px 0 0;
    }

    .reviews-rating-star svg {
        width: 20px;
        height: 20px;
    }

    .custom-carousel-item:after {
		display: none;
	}
}

@media (max-width: 1199.98px) {
    .company-subscription-section .subscaption-items-block {
        padding-left: calc(var(--bs-gutter-x)* .5);
    }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
	.custom-carousel-item:after {
		background-size: auto;
	}

	.custom-carousel-item .carousel-caption {
		bottom: 5rem;
	}

    .reviews-card {
        padding: 20px;
    }

    .reviews-rating-star svg {
        width: 20px;
        height: 20px;
    }

    .header-block .login-type-style {
		padding-right: 100px;
	}

    .play-store-block img,
    .custom-carousel-block button.use-web-version-btn {
		height: 45px;
	}

    .reviews-block {
        padding: 60px 0 20px;
    }
}

@media (max-width: 767.98px) {

    .footer-subscribe #button-addon {
        padding-left: 20px;
        padding-right: 20px;
    }

    .actiontop-block .input-group input {
        padding-right: 135px !important;
    }

    .header-block .navbar-brand {
		/* height: 104px;
		width: 104px;
        min-width: 104px; */
        height: 72px;
        width: 72px;
        min-width: 72px;
	}

    .switch-app-type .form-check .form-check-label {
        padding: 0 10px !important;
        /* height: 36px; */
        height: 60px;
    }
    
    header {
        border-bottom-right-radius: 14px;
    }

    .header-nav-menu:after {
        display: none;
    }

    .header-block {
        height: 60px;
    }

    .header-block .container-fluid {
        padding: 0;
        position: relative;
    }

    .header-nav-menu {
        position: relative;
        display: flex;
        align-items: flex-start;
        top: 0;
        left: 0;
        border-bottom-right-radius: 14px;
        width: 80%;
        /* width: 100%; */
    }

    .switch-app-type {
        width: 100%;
    }

    .personal_id {
        width: 50%;
    }

    .business_id {
        width: 50%;
    }

    .header-menu-position {
        right: 0px;
        bottom: -40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-block .navbar-brand {
        border-bottom-right-radius: 14px;
        overflow: hidden;
	}
    .switch-app-type .form-check .form-check-label {
        justify-content: center;
    }

    .custom-mobile-burger-menu {
        background: #ffffff80 !important;
        border-radius: 4px !important;
        padding: 10px 12px !important;
        position: absolute;
        top: -84px;
        right: 0;
    }

	.custom-carousel-block .custom-carousel-item {
		aspect-ratio: 4 / 3 !important;
	}

	.custom-carousel-item .carousel-caption {
		bottom: 0.2rem;
		z-index: 99;
	}

	.custom-carousel-label {
		margin-bottom: 5rem;
		position: relative;
		right: 1rem;
	}

    .carousel-button-block {
        justify-content: center;
    }

	.custom-carousel-label h1 {
		color: var(--base-graphit);
		width: 100%;
		text-align: right;
		padding-left: 30%;
        font-size: clamp(17px, 5.8vw, 60px) !important;
	}

	/*.custom-carousel-item:after {*/
	/*	content: '';*/
	/*	display: inline-block;*/
	/*	width: 50%;*/
	/*	height: 100%;*/
	/*	position: absolute;*/
	/*	top: 0;*/
	/*	right: 0;*/
	/*	bottom: 0;*/
	/*	left: inherit;*/
	/*	background: rgb(255, 255, 255);*/
	/*	background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);*/
	/*	background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);*/
	/*	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);*/
	/*	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);*/
	/*}*/

    .navigation-item:before {
        display: none;
    }

    .footer-navigation .footer-label,
    .footer-subscribe .footer-label {
        margin-bottom: 20px;
    }

    .legal-notice-block,
    .legal-notice-block ul {
        flex-direction: column;
    }

    .legal-notice-block ul li {
        margin-bottom: 15px;
    }

    .legal-notice-block {
        padding: 35px 0;
    }

    .presentaion-block {
		padding-bottom: 3rem !important;
		padding-top: 1.5rem !important;
	}

    .getintouch-button-block .play-store-block {
        justify-content: center;
    }

    .getintouch-label {
        justify-content: center;
    }
}

@media (max-width: 574px) {
    .custom-carousel-label {
		margin-bottom: 2rem;
	}
    .custom-carousel-item .carousel-button-block .d-grid,
	.custom-carousel-item .carousel-button-block .d-grid .play-store-block {
        gap: .7rem !important;
    }
}

@media (min-width: 575.98px) and (max-width: 767.98px) {
	.startfinding-container-bg:before {
		height: 88px;
	}
}

@media (max-width: 575.98px) {
	.startfinding-block {
		padding: 30px 0 0;
	}

    .reviews-card {
        padding: 20px 20px 40px;
    }

    .reviews-item {
        margin-bottom: 20px;
    }

    .play-store-block img,
    .custom-carousel-block button.use-web-version-btn {
		height: 45px;
	}
}

@media (min-width: 320px) and (max-width: 575.98px) {
	/* .header-menu-position {
        right: 0;
        bottom: 0;
    } */
    .actiontop-block {
        padding:0px   !important;
    }
    .actiontop-container-bg {
        border-radius:0px !important;
    }
    /*.carousel-caption {*/
    /*    text-align: center;*/
    /*    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(115 33 53) 50%);*/
    /*    bottom: 0 !important;*/
    /*}*/
    .new-title {
        align-items: flex-end;
        padding-bottom: 20px;
    }
    .new-title h1{
        font-size: 30px !important;
    }
    .startfinding-item p {
        font-size:20px;
    }
    .custom-carousel-item > img {
        opacity: .7 !important;
    }
    .startfinding-block {
        padding: 50px 0 0;
        margin-bottom: -1px;
        text-align: center;
        height: 330px;
        background: -webkit-linear-gradient(top, var(--base-silver), var(--base-silver) 230px, var(--base-graphit) 100px, var(--base-graphit));
    }
    .getintouch-block {
        padding:20px 0 !important;
    }
    .getintouch-label {
        margin-bottom: 0px!important;
        flex-direction: column;
    }
    .getintouch-label a,
    .getintouch-label h1 {
        font-size: clamp(15px, 3.8vw, 48px) !important;
    }
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
    /* .header-block {
		height: 85px;
	} */

	.phone-banner {
		padding: 5px;
		border-radius: 30px;
	}

	.presentaion-block {
		padding-top: 40px;
		padding-bottom: 60px;
	}

    .footer-subscribe .input-group {
        height: 45px;
    }

    .footer-subscribe #button-addon {
        padding-left: 20px;
        padding-right: 20px;
    }

    .actiontop-block .input-group input {
        padding-right: 135px !important;
    }

    .navigation-item:before {
        left: -18px;
    }

    .custom-carousel-item .carousel-caption {
		bottom: 3rem;
	}

    .play-store-block img,
    .custom-carousel-block button.use-web-version-btn {
		height: 36px;
	}

    .phone {
        margin-bottom: 20px;
    }

    .footer-navigation .footer-label, .footer-subscribe .footer-label {
        margin-bottom: 40px;
    }

    .legal-notice-block {
        padding: 45px 0;
    }
}

@media (max-width: 350px) {
    .reviews-date {
        font-size: 15px;
    }
}

@media(min-width: 320px) and (max-width: 450px) {
	.header-block {
		height: 52px;
	}

	.header-block .login-type-style {
		padding-right: 25px;
	}

	.header-block .navbar-brand {
		/* height: 104px;
		width: 104px;
        min-width: 104px; */
        height: 65px;
        width: 65px;
        min-width: 65px;
	}
    .switch-app-type .form-check .form-check-label {
        padding: 0 10px !important;
        /* height: 36px; */
        height: 52px;
    }

    .play-store-block img,
    .custom-carousel-block button.use-web-version-btn {
		height: 35px;
	}

    .easiestway-item h3 {
        /* font-size: 27px; */
        margin-bottom: 20px;
    }

    .easiestway-item p,
    .easiestway-item h5 {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .custom-mobile-burger-menu {
        transform: scale(0.5);
        right: -10px;
        top: -81px;
    }
}

@media (min-width: 280px) and (max-width: 320px) {
	.custom-carousel-item .carousel-button-block .d-grid,
	.custom-carousel-item .carousel-button-block .d-grid .play-store-block {
        gap: .3rem !important;
    }

    .custom-carousel-item .carousel-caption {
        bottom: 0;
        padding-bottom: .7rem;
    }

    .mobile-slider-container .slick-slide {
        padding: 10px !important;
    }

    .easiestway-item p {
        margin-bottom: 40px;
    }

    .easiestway-container-bg {
        padding: 50px 0;
    }

    .getintouch-label a,
    .getintouch-label h1 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .new-title h1 {
        font-size: 35px;
    }

    .startfinding-item p {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .startfinding-item h2 {
        font-size: 18px;
    }

    .presentaion-block {
		padding-bottom: 2.5rem !important;
		padding-top: 1rem !important;
	}

    .legal-notice-block ul {
		flex-direction: column;
	}

    .legal-notice-block ul li {
        margin-right: 5px;
        font-size: 15px;
        margin-bottom: 10px;
    }

    .legal-notice-block {
        padding: 30px 0;
        margin-top: 30px;
    }

    .footer-subscribe #button-addon {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
    }

    .actiontop-block .input-group input {
        padding-right: 115px !important;
    }

    .footer-block .footer-item:last-child {
        margin-bottom: 0 !important;
    }

    .header-block {
		height: 38px;
	}

    .custom-mobile-burger-menu img {
        transform: scale(0.7);
    }

	/* .header-nav-menu {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	} */

	/* .header-block .navbar-brand {
		height: 75px;
		width: 75px;
	} */

    .header-block .navbar-brand {
		/* height: 75px;
		width: 75px;
        min-width: 75px; */
        height: 50px;
        width: 50px;
        min-width: 50px;
	}

    .header-menu-position {
        height: 30px;
        width: 50px;
        bottom: 0;
    }

    .switch-app-type .form-check .form-check-label {
        padding: 0 10px !important;
        height: 38px;
        font-size: 12px;
    }

    .custom-mobile-burger-menu {
        top: -32px;
        right: -10px;
        padding: 0px 4px !important;
        transform: scale(0.45);
    }

    .reviews-rating-star svg {
        width: 18px;
        height: 18px;
    }

    .reviews-card {
        padding: 15px 15px 25px;
    }

    .reviews-date {
        font-size: 15px;
    }

    .play-store-block img,
    .custom-carousel-block button.use-web-version-btn {
		height: 30px;
	}

    .custom-carousel-label {
        margin-bottom: 1.5rem;
    }

    .advantages-card {
        padding: 20px 20px 30px;
    }

    .easiestway-item h3 {
        /* font-size: 25px !important; */
        margin-bottom: 20px;
    }

    .easiestway-item p,
    .easiestway-item h5 {
        font-size: 14px;
        margin-bottom: 15px;
    }
}
