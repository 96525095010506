.startfinding-block {
	padding: 50px 0 0;
	margin-bottom: -1px;
	text-align: center;
	height: 330px;
	background: -webkit-linear-gradient(top, #ffffff, #ffffff 230px, var(--base-graphit) 100px, var(--base-graphit));
}

.startfinding-block * {
	color: #ffffff;
}

.startfinding-container-bg {
	background-color: var(--base-bordo);
	/* padding: 60px 0; */
    position: relative;
	height: 100%;
	display: flex;
    justify-content: center;
    align-items: center;
}

.startfinding-item {
    z-index: 99;
}

.startfinding-item h2 {
	font-family: ubuntuMedium;
    /* line-height: 1; */
	font-size: clamp(24px, 4vw, 32px);
    margin-bottom: 35px;
}

.easiestway-item h3 {
    font-size: clamp(24px, 4vw, 32px);
    margin-bottom: 35px;
}

.startfinding-item p {
	font-size: 25px;
    margin-bottom: 35px;
}

.startfinding-btn {
	font-size: clamp(14px, 2.6vw, 18px) !important;
	text-transform: uppercase;
	line-height: 1;
	font-family: ubuntuMedium;
	width: 100%;
	height: 50px;
	z-index: 99;
	position: relative;
}

.startfinding-btn:active,
.startfinding-btn:hover {
	background: var(--base-graphit) !important;
	border-color: #ffffff;
	color: #ffffff !important;
}

.startfinding-btn:hover span {
	display: inline-block;
	transform: scale(1.03);
	transition: all 0.1s;
}

.startfinding-btn span  {
    font-family: ubuntuMedium;
}

.startfinding-btn:active span  {
    color: #ffffff;
}

.startfinding-container-bg:before {
    content: '';
    width: 274px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-right-radius: 50px;
    background-color: #661e31;
}

.startfinding-container-bg:after {
    content: '';
    width: 100px;
    height: 180px;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 50px;
    background-color: #661e31;
}
