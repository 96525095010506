.getintouch-block {
    background: var(--base-graphit);
    padding: 60px 0;
    margin-bottom: -1px;
}

.getintouch-block .row {
    display: flex;
    align-items: center;
}

.getintouch-label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.getintouch-label h1,
.getintouch-label a {
    color: #ffffff;
    margin-bottom: 0;
    font-size: clamp(40px, 3.8vw, 48px);
    line-height: 1;
}

.getintouch-label h1 {
    margin-right: 16px;
}

.getintouch-button-block a {
    transition: all .2s;
}

.getintouch-button-block a:hover {
    transform: scale(1.05);
}

.getintouch-button-block .play-store-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.getintouch-button-block .play-store-block img {
    height: 45px;
}