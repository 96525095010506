:root {
	/* --base-bordo: #7f1535; */
	--base-bordo: #732135;
	--base-graphit: #2a2a35;
	--base-silver: #eff0f2;
	--base-text-color: #2a2a35;
}

@font-face {
	font-family: ubuntuBold;
	src: url(./assets/font/Ubuntu-Bold.ttf);
}

@font-face {
	font-family: ubuntuLight;
	src: url(./assets/font/Ubuntu-Light.ttf);
}

@font-face {
	font-family: ubuntuMedium;
	src: url(./assets/font/Ubuntu-Medium.ttf);
}

@font-face {
	font-family: ubuntuRegular;
	src: url(./assets/font/Ubuntu-Regular.ttf);
}

html {
	scroll-behavior: smooth;
	/*Adds smooth scrolling instead of snapping to element*/
}

body * {
	font-family: ubuntuRegular;
	color: var(--base-text-color);
}

.App .container {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.App .row {
	margin: 0 !important;
}

.App .container,
.App .container-fluid,
.App .container-xs,
.App .container-sm,
.App .container-md,
.App .container-lg,
.App .container-xl,
.App .container-xxl {
	max-width: 1140px;
}
