.presentaion-block {
	padding-top: 50px;
	padding-bottom: 50px;
	background: var(--base-silver);
	height: 100%;
}

.phone-item {
	position: relative;
}

.phone-item img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.phone-mockup-style {
	position: relative;
	overflow: hidden;
}

.phone-mockup-style .phone-mockup-img {
	position: relative;
	display: inline-block;
	width: 100%;
	height: auto;
	z-index: 99;
}

.phone-banner {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	padding: 10px;
	border-radius: 40px;
}

.mobile-slider-container .phone-banner {
	position: relative !important;
	width: 100%;
	padding: 0;
	border-radius: 0 !important;
}

.mobile-slider-container .slick-slide {
	padding: 15px;
}

.mobile-phone-mockup-style {
	border-radius: 14px;
	overflow: hidden;
}

/* Subscription style */

.subscription-information h2 {
	font-family: ubuntuBold;
	font-size: 36px;
	margin-top: 0;
	margin-bottom: 15px;
}

.subscription-information p {
	font-family: ubuntuMedium;
	font-size: 16px;
	/* margin-top: 0; */
}

.subscaption-items-block {
	overflow: scroll;
	touch-action: auto;
	/* padding: 0 0 30px; */
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
}

.company-subscription-section .subscription-btn {
	border: 2px solid #4eab88;
	color: #4eab88 !important;
	font-family: ubuntuBold;
	font-size: 16px;
	line-height: 1;
	background: #ffffff;
	padding: 4px 15px;
	height: 32px;
}

.company-subscription-section .subscription-btn:hover {
	background: #4eab88 !important;
	border: 2px solid #4eab88 !important;
	color: #ffffff !important;
}

.subscaption-card-item .item-header h4 {
	margin-top: 0;
	color: #ecb576;
	font-family: ubuntuBold;
	font-weight: 900;
	font-size: 16px;
	text-transform: uppercase;
	margin-bottom: 0;
}

.subscaption-card-item .item-header p {
	color: #ecb576;
	margin: 0 0 15px;
	font-family: ubuntuRegular;
	font-size: 16px;
}

.subscaption-card-item .item-header .month-price,
.subscaption-card-item .item-header .month-price span,
.subscaption-card-item .item-header .year-price span,
.subscaption-card-item .item-header .year-price {
	color: #ffffff;
}

.subscaption-card-item .item-header .month-price span,
.subscaption-card-item .item-header .year-price span {
	font-family: ubuntuBold;
	font-size: 27px;
}

.subscaption-card-item .item-header .month-price,
.subscaption-card-item .item-header .year-price {
	font-family: ubuntuRegular;
	font-size: 14px;
}

.subscaption-card-item .item-header .rounded-pill {
	font-size: 11px;
	padding: 4px 7px;
}

.subscaption-items-block .ant-space {
	align-items: stretch;
}

.subscaption-items-block .ant-card-body {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 0 0 20px;
}

.subscaption-card-item .item-header .year-price {
	line-height: 32px;
	display: block;
	height: 32px;
	margin-right: 5px;
}

.subscaption-card-item .item-header .junker-style {
	display: flex;
	flex-direction: column;
}

.subscaption-card-item .item-header .junker-style span {
	line-height: 1;
	color: #ffffff;
}

.subscaption-card-item .item-header {
	background: url('../../assets/img/subscription_bg.png') center center no-repeat;
	background-size: cover;
	padding: 12px;
}

.subscaption-items-block .card {
	width: 210px;
	min-width: 210px;
	height: 100%;
	overflow: hidden;
	border-color: #aeaeae;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 0 0 20px;
	cursor: pointer;
}

.subscaption-items-block {
	width: 100%;
}

.subscaption-items-block .flicking-viewport {
	padding: 20px 75px 20px 0;
}

.subscaption-items-block .flicking-camera {
	gap: 16px;
}

.subscaption-card-item .offer-list {
	padding: 0 8px;
	margin: 0;
	border-top: 2px solid #eff0f2;
}

.subscaption-card-item .offer-list .offer-list-item {
	list-style: none;
	display: flex;
	justify-content: space-between;
	/* align-items: center; */
	border-bottom: 1px solid #b1b1b4;
	padding: 10px 0;
}

.subscaption-card-item .offer-list .offer-list-item:first-child {
	padding-top: 8px;
}

.subscaption-card-item .offer-list .offer-list-item:last-child {
	border-bottom: none;
}

.subscaption-card-item .offer-list .offer-list-item span {
	min-width: 32px;
	display: inline-table;
	font-family: ubuntuBold;
	font-weight: 900;
	font-size: 14px;
	height: 22px;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	text-wrap: nowrap;
	text-align: right;
}

.subscaption-card-item .offer-list .offer-list-item p {
	margin: 0;
	padding-right: 7px;
	font-family: ubuntuRegular;
	font-size: 14px;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: inline-block;
	white-space: pre-wrap
}

.subscaption-card-item .offer-list .offer-list-item span svg {
	font-size: 22px;
	text-align: left;
	height: 22px;
	width: auto;
}

.subscaption-card-item .offer-list .offer-list-item svg.icon-close-circle {
	fill: var(--base-bordo) !important;
	background: #ffffff;
	border-radius: 50%;
}

.subscaption-card-item .offer-list .offer-list-item svg.icon-check-circle {
	fill: #4eab88 !important;
	background: #ffffff;
	border-radius: 50%;
}

.subscaption-items-block .card:hover {
	background: #23233b;
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.subscaption-items-block .card:hover .offer-list .offer-list-item span,
.subscaption-items-block .card:hover .offer-list .offer-list-item p {
	color: #ffffff !important;
}

.mobile-subscription .slick-list .slick-slide {
	max-width: fit-content;
}