.reviews-block {
    padding: 75px 0 25px;
}

.reviews-card {
	border: 2px solid #f5f6f7;
	border-radius: 12px;
    padding: 20px 20px 30px;
	font-size: 16px;
	height: 100%;
    transition: all .3s;
    background: #ffffff;
}

.reviews-item:hover .reviews-card {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 20px 30px 10px rgba(239, 240, 242, 0.5);
	-moz-box-shadow: 0px 20px 30px 10px rgba(239, 240, 242, 0.5);
	box-shadow: 0px 20px 30px 10px rgba(239, 240, 242, 0.5);
}

.reviews-img img {
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.reviews-title {
    font-size: clamp(14px, 1.45vw, 17px);
    font-family: ubuntuBold;
    color: #2a2a35;
    margin-bottom: 15px;
}

.reviews-user {
    margin-bottom: 15px;
}

.reviews-user-avatar {
    margin-right: 10px;
}

.reviews-rating {
    margin-bottom: 15px;
}

.reviews-date {
    color: var(--base-bordo);
    font-family: ubuntuLight;
    font-size: 16px;
}

.reviews-rating-star svg {
    fill: var(--base-bordo) !important;
}

.reviews-content {
    font-size: clamp(15px, 0.75vw, 17px);
    font-family: ubuntuLight;
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
}

.reviews-rating-star {
    line-height: 1;
}

#reviews {
	scroll-margin-block-start: 70px;
	scroll-margin-top: 70px;
}