.advantages-block {
	padding-bottom: 60px;
}

.advantages-block .advantages-number {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--base-bordo);
	width: 48px;
	height: 48px;
	font-weight: bold;
	position: absolute;
	top: -24px;
	font-family: ubuntuBold;
	transition: all .3s;
}

.advantages-block .advantages-number span {
	color: #ffffff;
}

.advantages-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding-top: 60px;
}

.advantages-card {
	border: 1px solid #f5f6f7;
	border-radius: 12px;
	padding: 30px 30px 50px;
	font-size: clamp(14px, 2.6vw, 16px);
	height: 100%;
	-webkit-box-shadow: 0px 20px 30px 10px rgba(239, 240, 242, 0.5);
	-moz-box-shadow: 0px 20px 30px 10px rgba(239, 240, 242, 0.5);
	box-shadow: 0px 20px 30px 10px rgba(239, 240, 242, 0.5);
    transition: all .3s;
	font-family: ubuntuLight;
}

.advantages-item:hover .advantages-card {
    transform: scale(1.05);
}

.advantages-item:hover .advantages-number {
    background: var(--base-graphit)
}