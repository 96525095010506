.actiontop-block {
    padding: 50px 10px 0;
    margin-bottom: -1px;
    text-align: center;
    height: 250px;
    background: var(--base-silver);
}

.actiontop-block * {
    color: #ffffff;
}

.actiontop-container-bg {
    border-radius: 15px;
    background-color: var(--base-bordo);
    /* padding: 60px 0; */
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.actiontop-block svg.icon-check-circle {
    fill: #4eab88 !important;
    background: #ffffff;
    border-radius: 50%;
}

.actiontop-item {
    z-index: 99;
}

.actiontop-item h2 {
    font-family: ubuntuMedium;
    /* line-height: 1; */
    font-size: clamp(24px, 4vw, 32px);
    margin-bottom: 35px;
}

.easiestway-item h3 {
    font-size: clamp(24px, 4vw, 32px);
    margin-bottom: 35px;
}

.actiontop-item p {
    font-size: 16px;
    margin-bottom: 35px;
}

.actiontop-btn {
    font-size: clamp(14px, 2.6vw, 18px) !important;
    text-transform: uppercase;
    line-height: 1;
    font-family: ubuntuMedium;
    width: 100%;
    height: 50px;
    z-index: 99;
    position: relative;
}

.actiontop-btn:active,
.actiontop-btn:hover {
    background: var(--base-graphit) !important;
    border-color: #ffffff;
    color: #ffffff !important;
}

.actiontop-btn:hover span {
    display: inline-block;
    transform: scale(1.03);
    transition: all 0.1s;
}

.actiontop-btn span  {
    font-family: ubuntuMedium;
}

.actiontop-btn:active span  {
    color: #ffffff;
}

.shakeme {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
}

.actiontop-block .input-group input {
    padding-right: 165px;
}

.actiontop-block .subscribe-btn {
    display: flex;
    align-items: center;
}

@keyframes shake {
    0% { margin-left: 0rem; }
    25% { margin-left: 0.5rem; }
    75% { margin-left: -0.5rem; }
    100% { margin-left: 0rem; }
}
