.custom-carousel-block button.use-web-version-btn {
	border-radius: 5px;
	font-size: clamp(14px, 2.6vw, 18px) !important;
	background: var(--base-bordo) !important;
	border-color: #ffffff !important;
	text-transform: uppercase;
	height: 50px;
	display: flex;
    justify-content: center;
    align-items: center;
}
.custom-carousel-block {
	background: var(--base-bordo) !important;
	position: relative;
}

.custom-carousel-block h1,
.use-web-version-btn span {
	color: #ffffff;
	font-family: ubuntuMedium;
}

.custom-carousel-item {
	display: flex !important;
	justify-content: center;
	position: relative;
}

.custom-carousel-item > img {
	object-fit: cover;
}

.custom-carousel-item .carousel-caption {
	bottom: 6rem;
	left: 0;
	right: 0;
}

.play-store-block img {
	width: 100%;
	height: 50px;
	/* border-radius: 12px; */
}

.custom-carousel-label {
	z-index: 99;
	text-align: left;
	display: flex;
	align-items: flex-end;
}

.custom-carousel-label h1 {
	display: inline-block;
	font-size: clamp(20px, 4.8vw, 60px);
	text-wrap: balance;
	width: 77%;
	margin-bottom: 0;

	text-shadow:
			-1px -1px 0 var(--base-bordo),
			0   -1px 0 var(--base-bordo),
			1px -1px 0 var(--base-bordo),
			1px  0   0 var(--base-bordo),
			1px  1px 0 var(--base-bordo),
			0    1px 0 var(--base-bordo),
			-1px  1px 0 var(--base-bordo),
			-1px  0   0 var(--base-bordo);
}

.custom-carousel-block button.use-web-version-btn:hover {
	background: var(--base-graphit) !important;
	border-color: #ffffff !important;
}

.custom-carousel-block button.use-web-version-btn:hover span {
	display: inline-block;
	transform: scale(1.03);
	transition: all 0.1s;
}

.custom-carousel-item:after {
	content: '';
	display: inline-block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	background: url('../../assets/img/banner_front_2.png') center center no-repeat;
	pointer-events: none;
	background-size: contain;
}

.carousel-button-block {
	display: flex;
	justify-content: flex-end;
	z-index: 99;
}

.carousel-button-block a {
	transition: all 0.2s;
}

.carousel-button-block a:hover {
	transform: scale(1.05);
}

.custom-carousel-row {
	display: flex;
    align-items: center;
}
.new-title {
	position: absolute;
	text-align: center;
	z-index: 99;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.new-title h1 {
	font-size: clamp(60px, 4.8vw, 60px);
}
