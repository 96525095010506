.easiestway-block {
    background-color: var(--base-bordo);
    text-align: center;
    max-height: 330px;
    height: 330px;
}

.easiestway-block * {
    color: #ffffff;
}

.easiestway-container-bg {
    background: url(../../assets/img/image_box.png) center center no-repeat;
    background-size: cover;
    /* padding: 70px 0; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-down {
    display: inline-block;
    width: 40px;
    height: auto;
    transition: all .3s;
}

.easiestway-block .icon-down svg {
    border-radius: 50%;
}

.easiestway-block .icon-down:hover svg {
    background: var(--base-graphit);
}

.easiestway-block .icon-down:hover {
    transform: scale(1.1)
}

.easiestway-item h3 {
    font-size: clamp(24px, 4vw, 32px);
    margin-bottom: 35px;
}

.easiestway-item p {
    font-size: 16px;
    margin-bottom: 35px;
}

.easiestway-item h5 {
    font-size: 16px;
    font-family: ubuntuBold;
    margin-bottom: 30px;
}