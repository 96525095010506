.footer-block {
	background: var(--base-graphit);
}

.color-white {
	color: #ffffff;
}

.footer-label {
	color: #9fa2af;
	text-transform: uppercase;
	font-family: ubuntuMedium;
	font-size: 18px;
}

.footer-block ul {
	padding-left: 0;
}

.footer-block ul li {
	list-style: none;
}

.footer-block a {
	text-decoration: none;
	color: #ffffff;
	transition: all 0.2s;
}

.footer-block a:hover {
	color: var(--base-bordo);
	padding: 4px 10px;
	background: #ffffff;
}

.footer-subscribe .form-control {
	color: #ffffff !important;
	background-color: #40404a;
	border: none;
    border-radius: 25px !important;
    padding-left: 20px;
}

.footer-subscribe .form-control::placeholder {
	color: #ffffff !important;
	font-size: 13px;
    position: relative;
    top: -1px;
}

.footer-subscribe .form-control:focus {
	color: #ffffff !important;
	background-color: #40404a;
	border-color: #732234;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgb(115 34 52 / 50%);
}

.phone-number,
.email-adrress {
	/* font-size: clamp(20px, 2.5vw, 30px); */
    font-size: clamp(18px, 2vw, 20px);
}

.phone .footer-label,
.email .footer-label {
	margin-bottom: 0;
}

.phone {
	margin-bottom: 25px;
}

.email {
	margin-bottom: 15px;
}

.footer-social-link {
    margin-bottom: 10px;
}

.footer-social-link ul li {
	margin-right: 7px;
	border-radius: 50%;
}

.footer-social-link ul li a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	line-height: 1;
	transition: all 0.2s;
}

.footer-social-link a:hover {
	/* background: inherit; */
	padding: inherit;
	transform: scale(1.25) translateY(-10px);
}

.footer-social-link a:hover svg {
	width: 24px;
	height: 24px;
}

.footer-social-link a svg {
	fill: #ffffff !important;
	width: 18px;
	height: 18px;
    transition: all 0.2s;
}

.footer-social-link .facebook a {
	background: #365493;
}

.footer-social-link .twitter a {
	background: #33ccff;
}

.footer-social-link .instagram a {
	background: #b02ac4;
}

.footer-social-link .google a {
	background: #d44132;
}

.navigation-item {
    position: relative;
}

.navigation-item:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 100%;
    background-color: #74747c;
    top: 0;
    bottom: 0;
    left: -28px;
    position: absolute;
}

.footer-navigation .footer-label,
.footer-subscribe .footer-label {
    margin-bottom: 50px;
}

.footer-navigation ul li,
.footer-subscribe p {
    line-height: 36px;
    font-size: clamp(18px, 2vw, 20px);
}

.footer-subscribe .input-group {
    max-width: 410px;
    height: 50px;
}

.footer-subscribe #button-addon {
    background: #ffffff;
    color: var(--base-graphit);
    font-size: 13px;
    text-transform: uppercase;
    font-family: ubuntuBold;
    border-radius: 25px;
    padding-left: 35px;
    padding-right: 35px;
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 99;
    transition: all .2s;
}

.footer-subscribe #button-addon:hover {
    background: var(--base-graphit);
    color: #ffffff;
}

.legal-notice-block {
    padding: 55px 0;
    border-top: 1px solid #74747c;
}

.legal-notice-block ul,
.legal-notice-block .copywrite {
    margin-bottom: 0;
}

.legal-notice-block ul li {
    margin-right: 30px;
    font-size: 16px;
}

.legal-notice-block .copywrite span {
    margin-right: 3px;
    font-family: ubuntuLight;
}

.legal-notice-block ul li a {
    position: relative;
}

.legal-notice-block ul li a:hover {
    padding: inherit;
    background: inherit;
    color: #ffffff;
}

.legal-notice-block ul li a:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    background: white;
    transition: all .2s;
}

.legal-notice-block ul li a:hover:after {
    width: 100%;
}

.footer-block .footer-item {
    padding-bottom: 20px;
}